import { useQuery } from "react-query";
import { endpoints } from "utils/endpoints";
import http from "utils/http";

const fetchReviews = (pageNumber) => {
  const newEndpoints = endpoints.reviews.getReview.replace(
    "?page={pagenumber}",
    "?page=" + pageNumber
  );
  return http().get(newEndpoints);
};

export function useFetchReviews(pageNumber) {
  return useQuery(
    ["fetchReviews", pageNumber],
    () => fetchReviews(pageNumber),
    {
      keepPreviousData: true,
    }
  );
}
