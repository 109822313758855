import React from "react";
import MetaImage from "assets/meta.svg";
import Ikea from "assets/review/ikea.png";
import User from "assets/review/user.png";

const TranslationsServices = () => {
  return (
    <section className=" mx-auto my-[50px] max-w-7xl  lg:px-4 md:px-[2.5rem] px-4 flex lg:flex-row flex-col lg:justify-between lg:gap-[100px] gap-[50px]">
      <div className="grid place-content-center  ">
        <h1 className="text-[#00173A] md:text-[45px] text-[32px] leading-[48px] font-semibold font-primary  lg:pr-0 md:pr-16">
          Tomedes Provides Translations for IKEA
        </h1>
        <p className="text-base font-opensans font-bold lg:my-[26px] mt-3  pr-10">
          “Tomedes works exceptionally well, responds immediately, delivers
          translations on time (or even earlier!) and is very helpful
          everytime.”
        </p>
        <span className="text-base font-[300] font-primary">
          María José Guiñez,
          <br /> Communication Analyst
          <br /> IKEA
        </span>
        <div
          className="flex items-center md:gap-5 gap-2 md:text-base text-sm font-[300] font-primary
         lg:mt-2"
        >
          {/* <p className="text-base font-primary">
            <span className="font-semibold"> IKEA , </span>Colombia & Peru
          </p> */}
        </div>
      </div>
      <img
        src={Ikea}
        alt="meta-image"
        className="lg:w-[600px]  xl:w-[640px] lg:h-[300px] xl:h-[355px]"
      />
    </section>
  );
};

export default TranslationsServices;
