import React from "react";

import MicrosoftSvg from "assets/review/agencyLogo/microsoft.svg";
import AdidasSvg from "assets/review/agencyLogo/adidas.svg";
import DecathlonSvg from "assets/review/agencyLogo/decathlon.svg";
import IkeaSvg from "assets/review/agencyLogo/ikea.svg";
import AmazonSvg from "assets/review/agencyLogo/amazon.svg";
import GoogleSvg from "assets/review/agencyLogo/Google.svg";
import YoutubeSvg from "assets/review/agencyLogo/youtube.svg";
import BloomburgSvg from "assets/review/agencyLogo/bloomberg.svg";
import SpotifySvg from "assets/review/agencyLogo/spotify.svg";
import WixSvg from "assets/review/agencyLogo/wix.svg";
import HsbcSvg from "assets/review/agencyLogo/hsbc.svg";
import CanonSvg from "assets/review/agencyLogo/canon.svg";
import MgmSvg from "assets/review/agencyLogo/mgm.svg";
import CodeSvg from "assets/review/agencyLogo/code.svg";
import SapSvg from "assets/review/agencyLogo/sap.svg";
import { Link } from "gatsby";

const agencylogo = [
  AmazonSvg,
  GoogleSvg,
  AdidasSvg,
  IkeaSvg,
  DecathlonSvg,
  SpotifySvg,
  WixSvg,
  HsbcSvg,
  CanonSvg,
  MgmSvg,
  CodeSvg,
  SapSvg,
];

export default function TranslationAgency() {
  return (
    <section className=" max-w-7xl  py-[50px] mx-auto">
      <h1 className="text-[#00173A] lg:text-4xl text-[26px]  text-center font-secondary font-bold leading-10">
        Trusted by Business Customers Worldwide
      </h1>

      <p className="text-[#00173A] font-opensans mt-[30px] text-base text-center  px-10 lg:max-w-5xl lg:mx-auto">
        Tomedes is the language partner of choice for over 95,000 industry
        leaders and global enterprises. Join our esteemed clientele and
        experience service that elevates your international communication
      </p>

      <div className="  lg:px-4 md:px-0  px-4  mt-[22px] grid place-items-center  lg:grid-cols-6 md:grid-cols-3 grid-cols-2 gap-[60px]  ">
        {agencylogo?.map((logo) => (
          <img src={logo} alt="agency-logo" />
        ))}
      </div>
      <div className="flex justify-center">
        <Link
          to="/our-customers"
          className="text-orange text-center mt-12 text-lg"
        >
          Get to know our customers &rarr;{" "}
        </Link>
      </div>
    </section>
  );
}
