import React from "react";
import BccIcon from "assets/review/bbc.svg";
import CarolinaIcon from "assets/review/carolina.svg";
import CodeIcon from "assets/review/code.svg";
import { Link } from "gatsby";

const cardData = [
  {
    id: 1,
    title: `“Speedy return & reasonably priced”`,
    descripition:
      "A Translation Company well equipped with knowledge of hundreds of different languages - speedy return & reasonably priced.",
    logo: BccIcon,
    customerName: "Katherine Lindsay",
    company: "BBC Studios",
    url: "https://www.trustpilot.com/users/60db02325f811c001128d575",
    style: "text-[#000]",
  },
  {
    id: 2,
    title: `“Tomedes has been nothing but kind, knowledgeable and efficient!”`,
    descripition:
      "Tomedes has been nothing but kind, knowledgeable and efficient!...Everything was seamless! I trusted Tomedes so much they’re now working...with an even bigger project: interpreting a multi-language Zoom meeting!",
    logo: CarolinaIcon,
    customerName: "Ashrena Ali",
    company: "Carolina Herrera",
    url: "https://www.trustpilot.com/users/60945782a8fc63001b6f847b",
    style: "text-[#000]",
  },
  {
    id: 3,
    title: `“Tomedes is a high quality translation provider.”`,
    descripition:
      "I am very satisfied with Tomedes's work ethic, organization, attention to detail, and customer support. They are quick to respond to feedback which was beneficial to the improvement of our localization program. We highly recommend Tomedes for localization projects in multiple languages.",
    logo: CodeIcon,
    customerName: "Jorge Castro",
    company: "Code.org",
    url: "https://www.trustpilot.com/reviews/61b11ffd2b49257fe29a48fa",
    style: "text-[#000]",
  },
];

const ReviewCard = () => {
  return (
    <section className="max-w-7xl my-[50px]  px-4 mx-auto grid lg:grid-cols-3 place-content-center gap-6">
      {cardData.map((item, i) => (
        <Link key={item.id} to={item.url} target="blank ">
          <div
            className="max-w-[410.67px] h-[450px] review-card  px-6 py-8 flex flex-col border border-[#E7EAEC] 
            rounded  justify-between  hover:bg-[#F4F9FF] hover:border-[#E7EAEC]"
          >
            <div className=" font-opensans text-base">
              <span className="font-bold  text-[#000]">
                {item.title}
                {i === 0 && <span className="invisible">{item.title}</span>}
              </span>
              <p className={`mt-3  ${item.style}`}>{item.descripition}</p>
            </div>
            <div className="flex flex-col gap-3 text-[#000] font-opensans  items-start font-semibold">
              <img src={item.logo} alt={item.company} className="h-[36px]" />
              <span className={`text-[22px] ${item.style}`}>
                {item.customerName}
              </span>
              <span className="text-base text-[#000]">{item.company}</span>
            </div>
          </div>
        </Link>
      ))}
    </section>
  );
};

export default ReviewCard;
