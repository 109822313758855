import StarRatingComponent from "react-star-rating-component";
import React, { useEffect, useState } from "react";
import { useFetchReviews } from "hooks/Reviews/useFetchReviews";
import Next from "assets/next.svg";
import Prev from "assets/prev.svg";
import moment from "moment";

const Review = () => {
  const orangeColor = "#FF7B16";
  const [pageNumber, setpageNumber] = useState(1);
  const { data } = useFetchReviews(pageNumber);
  // console.log(data?.data);

  //  useEffect(() => {
  //    if (data?.data) {
  //      setReviews((prevReviews) => {
  //        return [...prevReviews, ...data.data];
  //      });
  //    }
  //  }, [data]);
  //  console.log(data);

  return (
    <div className="lg:my-[50px] mt-[100px] mx-auto  pt-10 pb-[60px]  max-w-7xl lg:px-4 md:px-[2.5rem] px-4 text-black">
      <div className=" pb-[40px] border-b border-[#FF7B16]">
        <h1 className="font-primary text-4xl font-semibold lg:text-start text-center">
          Latest Reviews
        </h1>
        <p className="text-lg font-opensans mt-[15px] lg:text-start text-center">
          Our client satisfaction rate is 98%, specifically because we put the
          client at the heart of our philosophy. These are the reviews of the
          customers who have been satisfied with our service
        </p>
      </div>

      {data?.data.map((r, i) => {
        return !r ? (
          ""
        ) : (
          <div
            className="comment text-black border-b border-[#616161] py-10"
            key={i}
          >
            <div className="flex justify-between items-center">
              <div className="stars flex gap-x-2">
                <StarRatingComponent
                  name="rate1"
                  starCount={5}
                  value={r.rating}
                  editing={false}
                />
              </div>
              <p>{moment(r.datecreated).format("MMM D, YYYY")}</p>
            </div>

            <p className="py-4">{r.Reviews}</p>
            <h1>
              {r?.link ? (
                <a
                  href={r.link}
                  target="_blank"
                  className={r.Name === r.Company && "font-semibold"}
                >
                  {r.Name}
                </a>
              ) : (
                <span className={r.Name === r.Company && "font-semibold"}>
                  {r.Name}
                </span>
              )}
              {r.Name !== r.Company && (
                <>
                  {r?.link ? (
                    <a href={r.link} target="_blank" className="font-semibold">
                      , {r.Company}
                    </a>
                  ) : null}
                </>
              )}
            </h1>
            {r?.Platform && (
              <span>
                See review in{" "}
                {r?.link ? (
                  <a href={r.link} target="_blank" className="text-[#0000EE]">
                    {r?.Platform}
                  </a>
                ) : null}
              </span>
            )}
          </div>
        );
      })}

      {/* {allReviews &&
        allReviews?.map((review) => (
          <div
            key={review?.ID}
            className="py-5 border-b border-[#8F8F8F] p-5 hover:bg-[#F4F9FF] font-primary  user-review"
          >
            <Link to={review?.link} target="_blank">
              <div className="flex justify-between items-center">
                <StarRatingComponent
                  name="rate1"
                  starCount={5}
                  value={review?.rating}
                  editing={false}
                />

                <p className="text-sm">{formatDate(review?.datecreated)}</p>
              </div>
              <p className="mt-5 text-lg">{review?.Reviews}</p>
              <div className="mt-[14px] flex items-center gap-5">
                <a className="font-semibold text-base ">{review?.Name}</a>
              </div>
            </Link>
          </div>
        ))} */}
      <div className="flex justify-center gap-x-3 text-black pt-4 font-primary">
        {pageNumber > 1 ? (
          <a
            role="button"
            onClick={() => setpageNumber((page) => page - 1)}
            className="border border-[#FF7B16] flex items-center px-3 rounded-md"
          >
            <img src={Prev} className="w-4 h-4" />
          </a>
        ) : (
          <a
            role="button"
            // onClick={() => setpageNumber((page) => page - 1)}
            className="border border-gray bg-gray flex items-center px-3 rounded-md cursor-not-allowed"
          >
            <img src={Prev} className="w-4 h-4" />
          </a>
        )}

        <a
          role="button"
          className="px-4 py-2 rounded-md font-bold font-opensans"
          style={{
            color: pageNumber ? orangeColor : "",
            border: pageNumber ? `1px solid ${orangeColor}` : "1px solid gray",
          }}
        >
          {pageNumber}
        </a>
        {data?.meta.last_page !== data?.meta.current_page && (
          <>
            <a
              role="button"
              onClick={() => setpageNumber((page) => page + 1)}
              className="px-4 py-2 border border-[#DFE3E8] rounded-md font-bold font-opensans"
            >
              {pageNumber + 1}
            </a>

            {data?.meta.last_page - 1 !== data?.meta.current_page && (
              <>
                <a
                  role="button"
                  className="px-4 py-2 border border-[#DFE3E8] rounded-md font-bold font-opensans hidden md:block"
                  onClick={() => setpageNumber((page) => page + 2)}
                >
                  {pageNumber + 2}
                </a>
              </>
            )}
          </>
        )}
        {data?.meta?.last_page - data?.meta?.current_page > 3 && (
          <>
            . . .
            <a
              role="button"
              className="px-4 py-2 rounded-md font-bold font-opensans"
              style={{
                color:
                  data?.meta?.current_page === data?.meta.last_page
                    ? orangeColor
                    : "",
                border:
                  data?.meta?.current_page === data?.meta.last_page
                    ? `1px solid ${orangeColor}`
                    : "1px solid #DFE3E8",
              }}
              onClick={() => setpageNumber((page) => data?.meta.last_page)}
            >
              {data?.meta.last_page}
            </a>
          </>
        )}
        {pageNumber < data?.meta.last_page - 2 ? (
          <a
            role="button"
            onClick={() => setpageNumber((page) => page + 1)}
            className="border border-[#FF7B16] flex items-center px-3 rounded-md"
          >
            <img src={Next} className="w-4 h-4" />
          </a>
        ) : (
          <a
            role="button"
            // onClick={() => setpageNumber((page) => page - 1)}
            className="border border-gray bg-gray flex items-center px-3 rounded-md cursor-not-allowed"
          >
            <img src={Next} className="w-4 h-4" />
          </a>
        )}
      </div>
    </div>
  );
};

export default Review;
