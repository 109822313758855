import React from "react";
import GrayArrow from "assets/grayArrow.png";

const Banner = () => {
  return (
    <section className="review-banner  w-full lg:h-[366px] h-full  ">
      <div className="px-4 py-[88px] max-w-7xl mx-auto">
        <div className="flex gap-[14px] text-white items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M15.7926 8.25924L8.59343 0.254332C8.29026 -0.0834752 7.70793 -0.0834752 7.40476 0.254332L0.205564 8.25924C0.101941 8.37419 0.033923 8.51679 0.00978135 8.6697C-0.0143603 8.82261 0.00641428 8.97925 0.0695792 9.12057C0.197565 9.40955 0.483933 9.59526 0.799898 9.59526H2.39972V15.1987C2.39972 15.411 2.48399 15.6146 2.63401 15.7647C2.78402 15.9149 2.98748 15.9992 3.19963 15.9992H5.59936C5.81151 15.9992 6.01497 15.9149 6.16498 15.7647C6.315 15.6146 6.39927 15.411 6.39927 15.1987V11.9967H9.59891V15.1987C9.59891 15.411 9.68319 15.6146 9.8332 15.7647C9.98322 15.9149 10.1867 15.9992 10.3988 15.9992H12.7986C13.0107 15.9992 13.2142 15.9149 13.3642 15.7647C13.5142 15.6146 13.5985 15.411 13.5985 15.1987V9.59526H15.1983C15.3532 9.59592 15.505 9.55146 15.6351 9.4673C15.7652 9.38314 15.868 9.26291 15.931 9.12128C15.994 8.97966 16.0145 8.82274 15.9899 8.66967C15.9653 8.51661 15.8968 8.374 15.7926 8.25924Z"
              fill="#494949"
            />
          </svg>
          <img src={GrayArrow} alt="arrow-icon" className="z-10" />

          <span className="text-[15px] text-[#494949]">Testimonials</span>
        </div>
        <h1 className="lg:text-5xl text-4xl  font-primary font-bold text-[#012458] mt-[53px]">
          Reviews & Testimonials
        </h1>

        <p className="mt-[18px] text-[#000] text-lg font-opensans max-w-[626px]">
          This page shows the reviews of our 95,000+ clients, which range from
          private individuals to small and medium businesses to large
          corporations to national and local governments. We're rated 4.9 out of
          5 stars in our overall effort to broaden the horizons of our clients.
        </p>
      </div>
    </section>
  );
};

export default Banner;
