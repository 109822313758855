import Layout from "components/layout";
import React from "react";
import Reviews from "components/homepage/reviews";
import AllReviews from "components/reviewPageComps/allReviews";
import LeaveReview from "components/reviewPageComps/leaveReview";
import TranslateAgency from "components/homepage/translateAgency";
import GetStarted from "components/reviewPageComps/getStarted";
import TranslationServices from "components/homepage/translationServices";
import GetInTouch from "../components/getInTouch";
import Featured from "components/homepage/featured";
import SEO from "components/seo";
import Banner from "components/NewReview/Banner";
import TranslationsServices from "components/NewReview/TranslationsServices";
import Feedback from "components/NewReview/Feedback";
import ReviewCard from "components/NewReview/ReviewCard";
import Review from "components/NewReview/Review";
import Checkus from "components/NewReview/Check-us";
import HelpSection from "components/NewReview/HelpSection";
import TranslationAgency from "components/NewReview/TransaltionAgency";
import FollowUs from "components/NewReview/Follow-us";

export default function index() {
  const title = "Reviews | Tomedes";
  const description =
    "Learn about Tomedes translation service through our customers' experiences";
  const keywords = "tomedes reviews";

  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/reviews.php"
      />

      <Banner />
      <TranslationsServices />
      <Feedback />
      <ReviewCard />
      {/* <AllReviews /> */}
      <Checkus />
      <Review />
      <FollowUs />
      <TranslationAgency />
      {/* <HelpSection /> */}
      {/* <AllReviews /> */}
      {/* <Reviews
        backgroundCol="bg-darkBlue"
        head=" "
        content
        headB
        socialIcons=" "
      />
      <Checkus />
     
      <LeaveReview />
      <TranslateAgency
        head="Trusted by Business Customers"
        colorHead="Worldwide"
        BackgroundCol="bg-midBlue"
      />
      <GetStarted />
      <Featured />
      <GetInTouch backgroundCol="bg-darkBlue" /> */}
      <GetInTouch />
    </Layout>
  );
}
