import { ReviewData } from "components/reusableComponents/UsersReviews/reviewsConfig";
import { Link } from "gatsby";
import React from "react";

export default function Checkus() {
  return (
    <div className="lg:mt-[100px]  md:mt-[50px] max-w-7xl px-4 pb-[60px] flex flex-col justify-center mx-auto">
      <h2 className="text-2xl lg:block hidden  font-secondary font-semibold text-black text-center ">
        Explore Our Reputation: Third-Party Reviews
      </h2>

      <ul className="grid md:grid-cols-2 lg:grid-cols-4 gap-2 place-content-center  max-w-7xl mx-auto">
        {ReviewData &&
          ReviewData.map(({ link, img, alt }, i) => {
            return (
              <li key={i} className="flex items-center justify-center py-4">
                <Link to={link} target="_blank">
                  <img src={img} alt={alt} className="bg-white" />
                </Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
}
