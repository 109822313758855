import React from "react";
import StarRatingComponent from "react-star-rating-component";
import StarIcon from "assets//review/starIcon.svg";

const Feedback = () => {
  let rating = [];
  for (let i = 1; i <= 5; i++) {
    rating.push(<img src={StarIcon} alt={` reting${i}`} key={i} />);
  }

  return (
    <section className=" mx-auto lg:my-[50px] my-[100px] max-w-7xl px-4 flex lg:flex-row flex-col justify-between lg:gap-[100px] gap-[14px]">
      <div className="grid place-content-center">
        <h1 className="text-[#000] text-[32px] lg:text-start text-center leading-[48px] font-semibold font-primary">
          Featured Customer Feedback
        </h1>
        <p className="text-base font-opensans mt-[10px] lg:text-start text-center pr-10">
          Don’t take our word for it. Trust our customers.
        </p>
      </div>
      <div className="lg:block flex flex-col items-center justify-center ">
        <div className="flex  lg:justify-end gap-[15px] ">{rating}</div>
        <p className="mt-[14px] text-[#000] font-primary lg:text-start text-center text-lg ">
          4.9 out of 5 stars (1,798 overall reviews)
        </p>
      </div>
    </section>
  );
};

export default Feedback;
