import React from "react";
import XSvg from "assets/review/Social-icon/x.svg";
import FbSvg from "assets/review/Social-icon/fb.svg";
import LinkSvg from "assets/review/Social-icon/linkedin.svg";
import MetaSvg from "assets/review/Social-icon/meta.svg";

const FollowUs = () => {
  return (
    <div className="max-w-7xl  lg:hidden mx-auto  md:grid place-content-center   hidden">
      <h1 className="text-center  text-[#131313] text-xl font-primary font-semibold uppercase">
        FOLLOW US
      </h1>
      <div className="mt-[10px] flex gap-5">
        <img
          style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
          src={XSvg}
          alt="twitter-icon"
          className=" bg-black  hover:bg-[#FF7B16] h-[36px] w-[36px] p-[10px] rounded-full"
        />
        <img
          style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
          src={FbSvg}
          alt="facebook-icoon"
          className=" bg-black  hover:bg-[#FF7B16] h-[36px] w-[36px] p-[10px] rounded-full"
        />
        <img
          style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
          src={LinkSvg}
          alt="linkedin-icon"
          className=" bg-black  hover:bg-[#FF7B16] h-[36px] w-[36px] p-[10px] rounded-full"
        />
        <img
          style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
          src={MetaSvg}
          alt="meta-icon"
          className=" bg-black  hover:bg-[#FF7B16] h-[36px] w-[36px] p-[10px] rounded-full"
        />
      </div>
    </div>
  );
};

export default FollowUs;
